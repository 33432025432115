/* Add all variables here */

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$text-orange: #F7941E;
$wine-red-light: #5c0a31;
$wine-red-dark: #3d051f;
$wine-text-color: #37041c;
$light-grey: #efefef;
$yellow-cta: #ff9812;
$gold: #e0b55d;
$color-cloud-grey: #f6f6f6;
$color-grey: #d5d5d5;
$color-almost-black: #212529;

$color-charcoal-black: #000;
$theme-colors: (
  "primary": $yellow-cta,
  "secondary": $secondary,
  "success": $yellow-cta, //$success,
  "info": $yellow-cta, //$info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

$container-max-widths: (
  xl: 1280px
);

$header-height: 100px;

$border-divider: 1px solid $color-cloud-grey;
$breadcrumb-height: 30px;

@mixin supervin-box-shadow {
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
}

@mixin btn-primary {

  border-radius: 0;
  text-transform: uppercase;
  padding: 0.7rem 1.3rem;
  font-size: 0.8rem;
  background-color: $yellow-cta;
  border: solid 1px $yellow-cta;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}



